@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
/* Add custom styles below */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Meta", sans-serif;
}

.error {
  color: red;
  font-size: 12px;
}

.inputError {
  border: 1px solid red !important;
}

.inputError:focus {
  border: 1px solid red !important;
}

.button-primary {
  background-color: #5051f9 !important;
}

.button-primary:hover {
  background-color: #5051f9;
}

.text-button-primary {
  color: #5051f9;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.text-input-label {
  color: grey;
  font-size: 12px;
}

.PhoneInputInput {
  outline: none;
  background-color: transparent !important;
}

.PhoneInputInput:focus {
  outline: none;
  background-color: transparent !important;
}

.PhoneInput {
  background-color: white !important;
}

.PhoneInputCountryIcon--border {
  display: flex !important;
}

.css-qbdosj-Input {
  margin: 0px !important;
}

input {
  font-size: 13px;
  background-color: white !important;
}

.logoSize {
  width: 150px;
  height: 40px;
}

img {
  display: inline !important;
}

.css-13cymwt-control {
  border-radius: 0px !important;
  min-height: 20px !important;
  height: 34px;
}

.css-13cymwt-control:focus {
  border-color: blue !important;
  box-shadow: 0px 0px 0px 0px blue !important;
  outline: none !important;
  border-radius: 0px !important;
  min-height: 34px !important;
}
.css-1fdsijx-ValueContainer {
  font-size: 12px !important;
}
.css-1dimb5e-singleValue,
.css-1jqq78o-placeholder {
  padding-left: 5px !important;
}

.sidebar-bg {
  background-color: #5051f9;
}

.sidebar-bg-dark {
  background-color: #5051f9;
}

.text-primary {
  color: #5051f9;
}

.border-primary {
  border: 0.5px solid #5051f9 !important;
}

.border-b-primary {
  border-bottom: 0.5px solid #5051f9 !important;
}

.sidebar-icons {
  background-color: transparent;
  color: rgb(201, 200, 200);
  width: 5vh;
  height: 2vh;
  border-radius: 0.3rem;
  margin: 0.5rem 0px !important;
}

.sidebar-icons-text {
  color: rgb(201, 200, 200);
}

.sidebar-icons:hover {
  color: white;
  width: 5vh;
  height: 2vh;
  border-radius: 0.3rem;
  margin: 0.5rem 0px !important;
}

.sidebar-icons-text:hover {
  color: white;
}

.sidebar-icons-select {
  background-color: white;
  border: 1px solid white;
  color: #5051f9;
  width: 5vh;
  height: 2vh;
  border-radius: 0.3rem;
  margin: 0.5rem 0px !important;
}

.sidebar-icons-select-text {
  color: white;
}

.chart-card {
  height: 24rem;
}
.candidate-card {
  height: 18.8rem;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #fdfdfd;
}

::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e3e3e3;
  border-radius: 8px;
}

.logo {
  width: 6rem;
  height: 4rem;
}

.onboard-box {
  height: 95%;
  width: 80%;
  background-color: #5051f9;
  margin: 20px;
  border-radius: 50px 0px 50px 0px;
}

.carousal-icon {
  width: 100%;
  text-align: left;
}

.img-circle {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100px;
  border: 3px solid white;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: cover;
  background-position: center;
  background-color: white;
}

.upload-buttons {
  display: flex;
  justify-content: flex-end;
}

.interview-circle {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100px;
  border: 2px solid white;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: cover;
  background-position: center;
}

.interview-circle-count {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
}
.dashimg {
  display: flex;
  justify-content: flex-end;
}

.custom-file-upload {
  height: 40px;
  border: 1px solid #e7e7e7;
  opacity: 1;
}
.custom-file-upload label {
  text-align: center;
  width: 30%;
  float: right;
  margin-top: 2px;
  margin-right: 2px;
  color: white;
  font-weight: 100;
  padding: 7px 2px 7px 0px;
  background-color: #5bad2b;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 80%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.css-1fdsijx-ValueContainer {
  font-size: 80%;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(245, 245, 245, 0.834);
  z-index: 20;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.searchForm {
  display: flex;
  border: 1px solid rgb(235, 233, 233);
  border-radius: 5px;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

.searchButton {
  text-indent: -999px;
  overflow: hidden;
  width: 40px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  cursor: pointer;
  opacity: 0.7;
}
.bg-primary-tint {
  background-color: #9393fa;
}
.border-primary-tint {
  border: 0.5px solid #9393fa !important;
}
.button-primary-tint {
  background-color: #9393fa !important;
}
.disabled-div {
  pointer-events: none;
  opacity: 0.5;
}
.disabled-rating {
  pointer-events: none;
  opacity: 0.9;
}

.chip {
  background-color: #5051f9 !important;
}

.multiSelectContainer li:hover {
  background: #5051f9 !important;
}

.highlightOption {
  background: #fff !important;
  color: #2e2e2e !important;
}
.highlightOption:hover {
  background: #5051f9 !important;
  color: #fff !important;
}

pre {
  white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
  white-space: -pre-wrap; /* Opera */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word; /* IE 5.5+ */
}

.horizontal-rating {
  display: flex;
  flex-direction: row;
}
.style-module_emptyIcons__Bg-FZ {
  /* display: flex;
  flex-direction: row;
  align-items: center; */
}
